// #event-teaser-slider
.et-slider-main {

    margin-bottom: $gutter * 2;
    
    @include breakpoint('sm') {
        margin-bottom: $gutter * 5;
    }
    
    .et-slider-con {
        // use a special in-between breakpoint for enabling/disabling the flex layout
        // I have changed this again, all 'sm' breakpoints are now 'm'
        @include breakpoint('sm') {
            display: flex;
            flex-wrap: wrap;
        }
        
        .et-slide { // #et- flexslide-0, #et-slide-1 ...

            // 2 columns
            @include breakpoint('sm') {
                flex: 0 1 calc(50% - 8px);

                margin: 0 $gutterHalf ($gutter * 2);

                &:nth-of-type(odd) {
                    margin-left: 0;
                }
                &:nth-of-type(even) {
                    margin-right: 0;
                }
            }

            // 3 columns
            @include breakpoint('ml') {
                flex: 0 1 calc(33.3333% - 12px);
                // reset 2-col
                &:nth-of-type(odd),
                &:nth-of-type(even) {
                    margin: 0 $gutterHalf ($gutter * 2);
                }
                &:first-of-type {
                    margin-left: 0;
                }
                // each third has no margin right
                &:nth-of-type(3n) {
                    margin-right: 0;
                }
                // each forth has no margin left
                &:nth-of-type(3n+1) {
                    margin-left: 0;
                }
            }

            &_year {
                color: $primary;
                margin: $gutter 0;
                // the year above the box will be hidden on mobile
                &.invisible {
                    display: none;
                }
                @include breakpoint('sm') {
                    &.invisible {
                        // the year above the box will be there, but invisible
                        display: block;
                        opacity: 0;
                    }
                }

                @include breakpoint('s') {
                    margin: $gutter ($gutter * 1.5);
                }

            }

            &_card {
                background-color: $wht;
                padding: $gutter;
                padding-bottom: $gutter * 1.5;
                margin: $gutter 0;
                min-height: 90%;
                position: relative;

                @include breakpoint('s') {
                    display: flex;
                    //align-items: center;
                }
                
                &__date {
                    padding: 0 $gutterHalf $gutter;
                    border-bottom: 1px solid rgba($blk,.15);

                    h3, h4 {
                        margin: 0;
                        display:inline;
                    }

                    &.invisible {
                        padding-bottom: 0;
                        border-bottom: 0;

                        h3, h4 {
                            display: none;
                        }
                    }

                    @include breakpoint('s') {
                        border-bottom: 0;
                        border-right: 1px solid rgba($blk,.15);

                        h3, h4 {
                            display: block;
                        }

                        &.invisible {
                            padding-bottom: $gutter;

                            h3, h4 {
                                display: block;
                                opacity: 0;
                            }
                        }
                    }
                }

                &__txt {
                    padding: $gutterHalf;

                    h4 {
                        margin: 0;
                        margin-top: .5rem;
                    }

                    p {
                        font-size: .875rem;
                        line-height: 1.5;
                        margin: 0;
                        margin-top: $gutterHalf / 2;
                    }
                    @include breakpoint('s') {
                        padding: 0 $gutter;
                    }

                }

                &__cta {
                    padding: $gutter 0 $gutterHalf;

                    @include breakpoint('sm') {
                        padding-left: 0;
                        position: absolute;
                        bottom: $gutterHalf;
                    }

                    a {
                        font-size: $baseFontSize;
                        line-height: 1;
                        font-variant: small-caps;
                        letter-spacing: .1rem;

                        span {

                        }
                        
                    }
                }

            }

        }
    }
    .et-slider-indicator { // #et-slider-dots

    }
}

// the main cta below the slider
.events__cta {
    margin-bottom: $gutter;
    
    a {
        font-size: $baseFontSize;
        line-height: 1;
        font-variant: small-caps;
        letter-spacing: .1rem;
        border: 0;
    }
}