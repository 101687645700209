$arrow-size: 24px;
$arrow-thickness: 1px;

.sticky-tab {
    display: none;
}

@include breakpoint('m') {
    .sticky-tab {
        display: inline-block;

        &.sticky__window {
            //position: absolute;
        }

        &.sticky__section {

        }
        &.sticky__right {
            //right: 16px;
        }
    } 
}
.sticky-tab {
    transform: rotate(-90deg);
    transform-origin: left top;
    //z-index: 2;
    
    
    background-color: $primary;
    border: 1px solid $primary;
    border-radius: 4px;

    font-size: $baseFontSize;
	line-height: 1;
    font-variant: small-caps;
    letter-spacing: .1rem;

    

    .sticky_next, .sticky_back {

        display: inline-block;
       
        a {
            display: flex;
            color: $wht;
            padding: $gutter;
            overflow: hidden;
        }

        .arrow_next, .arrow_back {

            display: inline-block;
            position: relative;
            width: $arrow-size;
            height: $arrow-size;
            //background-color: $red;
            transition: transform .4s ease-in-out;

            &::before, &::after {
                content: "";
                position:absolute;
                left: $arrow-size / 4;
                width: $arrow-size / 2;
                height: $arrow-thickness;
                background-color: $wht;
            }

        }
        .arrow_next {
            &::before {
                transform: rotate(-45deg);
                top: $arrow-size / 3;

            }
            &::after {
                transform: rotate(45deg);
                //background-color: $red;
                top: $arrow-size * 2 / 3;
            }
            &:hover {
                transform: translate(-4px,0);
            }
        }
        .text_next {
            
            display: inline-block;
            overflow: hidden;
            width: 0;
            transition: .4s ease-in-out;
            transition-property: width;

            span {
                transform: translateX(-50px);
                display: inline-block;
                opacity: 0;
                transition: .4s ease-in-out;
                transition-property: transform, opacity;
                margin-left: $gutterHalf;
            }
        }
        a:hover {
            .arrow_back {
                transform: translate($gutterHalf,0);
            }
            .arrow_next {
                transform: translate(-$gutterHalf,0);
            }
            .text_next {
                // The width is set by Javascript, as a value of "auto" can't be animated
                span {
                    transform: translateX(0);
                    opacity: 100;
                }
                
            }
        }
    }
    .sticky_back {
        
        .arrow_back {

            &::before {
                transform: rotate(45deg);
                top: $arrow-size / 3;

            }
            &::after {
                transform: rotate(-45deg);
                //background-color: $red;
                top: $arrow-size * 2 / 3;
            }

        }
    }
}