.js.io {
    /* the animation base class */
    .fx_anim {
        /*&.fx_hidden {
            opacity: 0;
        }*/
        &.fx_in {
            opacity: 0;
            &.fx_trigger {
                animation: animFadeIn 0.6s ease-in-out forwards;
            }
        }
        /* basic fade-in animation with optional translate */
        &.fx_fade-in {
            opacity: 0;
            &.fx_trigger {
                // this is the default, I try to override it with javascript...
                transform: translate(0, 60px);
                animation: animFadeInOffset 0.6s ease-out forwards;
            }
        }
        /* stagger animation group */
        &.fx_stagger {
            // here we add opacity to the direct children
            > * {
                opacity: 0;
                &.fx_trigger {
                    transform: translate(0, 60px);
                    animation: animFadeInOffset 0.4s ease-out forwards;
                }
            }
            /* special stagger animation for image grid */
            &.fx_imgFadeZoom {
                > * {
                    opacity: 0;

                    &.fx_trigger {
                        transform: translate(0,0);
                        //transform-origin: center center;
                        animation: animFadeIn 0.6s ease-out forwards;
                        /* the new animation div wrapper for the image div */
                        > div:first-of-type {
                            transform: scale(1.15);
                            transform-origin: center center;
                            animation: animImgZoom 0.6s ease-out forwards;
                            animation-delay: inherit;
                        }
                    }
                }
            }
        }
        /* used for line animation (line above headline) */
        &.fx_grow-x {
            opacity: 0;
            transform: scaleX(0);
            transform-origin: left center;
            &.fx_trigger {
                transform: scaleX(1);
                transform-origin: left center;
                animation: animGrowX 0.4s ease-out forwards; // the nav line also uses .4s
                opacity: 1;
            }
        }
        /* used for single images (i.e. lawyer image) – basic settings */
        &.fx_img-reveal,
        &.fx_img-reveal-btt,
        &.fx_img-reveal-simple,
        &.fx_img-reveal-simple-btt,
        &.fx_img-reveal-simple-ltr {
            position: relative;
            overflow: hidden;
            > img {
                opacity: 0;
                transform: scale(1.4);
                transform-origin: center center;
            }
            > .img-overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        /* reveal-effect with extra color layer from left to right */
        &.fx_img-reveal {
            > .img-overlay {
                background-color: $lightgrey;
                transform: scaleX(0);
                transform-origin: left center;
            }
            &.fx_trigger {
                > img {
                    animation: animImgFadeIn 1.2s ease-out forwards;
                }
                > .img-overlay {
                    animation: animOverlaySlide 1.2s ease-in-out forwards;
                }
            }
        }
        /* reveal-effect with extra color layer from bottom to top (btt) */
        &.fx_img-reveal-btt {
            > .img-overlay {
                background-color: $lightgrey;
                transform: scaleY(0);
                transform-origin: bottom top;
            }
            &.fx_trigger {
                > img {
                    animation: animImgFadeIn 1.2s ease-out forwards;
                }
                > .img-overlay {
                    animation: animOverlaySlide_btt 1.2s ease-in-out forwards;
                }
            }
        }
        /* reveal-effect with extra color layer from left to right */
        &.fx_img-reveal-simple {
            > img {
                transform: scale(1.15);
            }
            > .img-overlay {
                background-color: $wht;
                transform: scaleX(0);
                transform-origin: right center;
            }
            &.fx_trigger {
                > img {
                    animation: animImgFadeInSimple 0.6s ease-in-out forwards;
                }
                > .img-overlay {
                    animation: animOverlaySlideSimple 0.6s ease-in-out forwards;
                }
            }
        }
        /* reveal-effect with extra color layer from bottom to top (btt) */
        &.fx_img-reveal-simple-btt {
            > img {
                transform: scale(1.15);
            }
            > .img-overlay {
                background-color: $wht;
                transform: scaleY(0);
                transform-origin: bottom top;
            }
            &.fx_trigger {
                > img {
                    animation: animImgFadeInSimple 0.6s ease-in-out forwards;
                }
                > .img-overlay {
                    animation: animOverlaySlideSimple_btt 0.6s ease-in-out forwards;
                }
            }
        }
        /* reveal-effect with extra color layer from left to right */
        &.fx_img-reveal-simple-ltr {
            > img {
                transform: scale(1.15);
            }
            > .img-overlay {
                background-color: $wht;
                transform: scaleX(0);
                transform-origin: left center;
            }
            &.fx_trigger {
                > img {
                    animation: animImgFadeInSimple 0.6s ease-in-out forwards;
                }
                > .img-overlay {
                    animation: animOverlaySlideSimple_ltr 0.6s ease-in-out forwards;
                }
            }
        }
    }
}
    // Nice with animations: we can leave out the default values
    /* ----------------------------------------------- */
    /* Simple fade-in */
    @keyframes animFadeIn {
        0% {
            //opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    /* ----------------------------------------------- */
    /* Fade-in + slide-in from bottom */
    @keyframes animFadeInOffset {
        0% {
            //opacity: 0;
            /* hab' das hier herausgenommen, dann kann ich es eventuell per Javascript verändern */
            //transform: translate(0, 60px);
        }
        100% {
            opacity: 1;
            transform: translate(0,0);
        }
    }
    /* ----------------------------------------------- */
    /* Grow X */
    @keyframes animGrowX {
        0% {
            transform: scaleX(0);
        }
        100% {
            //opacity: 1;
            transform: scaleX(1);
        }
    }
    /* ----------------------------------------------- */
    /* Image Animation FadeIn and Zoom Out - Solo used with image grid */
    @keyframes animImgZoom {
        0% { transform-origin: center center;}
        100% { transform: scale(1); transform-origin: center center;}
    }
    /* ----------------------------------------------- */
    /* Image Animation FadeIn and Zoom Out - coupled with Anim Overlay Slide */
    @keyframes animImgFadeIn {
        0% { opacity: 0; transform-origin: center center;}
        42% { opacity: 0; transform-origin: center center;}
        48% { opacity: 1; transform-origin: center center;}
        100% { opacity: 1; transform: scale(1); transform-origin: center center;}
    }
    /* ----------------------------------------------- */
    /* Image Overlay Slide from left to right - with colored overlay layer */
    @keyframes animOverlaySlide {
        0% { transform: scaleX(0); transform-origin: left center;}
        42% { transform: scaleX(1); transform-origin: left center; }
        48% { transform: scaleX(1); transform-origin: right center; }
        100% { transform: scaleX(0); transform-origin: right center; }
    }
    /* ----------------------------------------------- */
    /* Image Overlay Slide from bottom to top – with colored overlay layer */
    @keyframes animOverlaySlide_btt {
        0% { transform: scaleY(0); transform-origin: center bottom;}
        42% { transform: scaleY(1); transform-origin: center bottom; }
        48% { transform: scaleY(1); transform-origin: center top; }
        100% { transform: scaleY(0); transform-origin: center top; }
    }
    /* ----------------------------------------------- */
    /* Image Animation FadeIn and Zoom Out - coupled with Anim Overlay Slide */
    @keyframes animImgFadeInSimple {
        0% { opacity: 0; transform-origin: center center;}
        100% { opacity: 1; transform: scale(1); transform-origin: center center;}
    }
    /* ----------------------------------------------- */
    /* Image Overlay Slide from left to right - with colored overlay layer */
    @keyframes animOverlaySlideSimple {
        0% { transform: scaleX(1); transform-origin: left center; }
        100% { transform: scaleX(0); transform-origin: left center; }
    }
    /* ----------------------------------------------- */
    /* Image Overlay Slide from bottom to top – with colored overlay layer */
    @keyframes animOverlaySlideSimple_btt {
        0% { transform: scaleY(1); transform-origin: center top; }
        100% { transform: scaleY(0); transform-origin: center top; }
    }
     /* ----------------------------------------------- */
    /* Image Overlay Slide from left to right - with colored overlay layer */
    @keyframes animOverlaySlideSimple_ltr {
        0% { transform: scaleX(1); transform-origin: right center; }
        100% { transform: scaleX(0); transform-origin: right center; }
    }