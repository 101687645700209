.ea-year-section {
    margin-top: 5rem;
    border-top: 1px solid rgba($blk,.15);

    .ea-year {
        margin-top: 2.5rem;
        color: $primary;
        font-size: 1.5em;
    }
}

// ul
.ea-list {
    // Reset
    list-style: none;
    margin-top: 0;
    margin-left: 0;
    padding-left: 0;
}

// li
.ea-entry {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba($blk,.15);

    &:last-of-type {
        padding-bottom: 2rem;
        border-bottom: 0;
    }

    @include breakpoint('m') {
        display: flex;
        align-items: flex-end;
        padding-bottom: 4rem;
    }

    // div
    &_info {
        flex: 0 1 45%;

        h4 {
            color: $primary;
            margin-bottom: 0;
        }
        & > p {
            font-size: .875rem;
            line-height: 1.5;
            margin-top: $gutterHalf / 2;
        }
    }

    & > p {
        font-size: .875rem;
        line-height: 1.5;

        span {
            &::before {
                content: " — ";
            }
        }

        @include breakpoint('m') {
            margin-left: $gutter;

            span {
                display: block;
                &::before {
                    content: "";
                }
            }
        }
    }

    &_cta {
        margin-left: auto;
        margin-right: $gutter;
        // push from bottom: baseFontSize (16 px) * smaller font size of <p> * line-height (1.5)
        margin-bottom: $gutter * 0.875 * 1.5;
        margin-top: $gutter;

        a {
            font-size: $baseFontSize;
            line-height: 1;
            font-variant: small-caps;
            letter-spacing: .1rem;
        }

        @include breakpoint('m') {
            margin-top: 0;
        }

    }
}