/*
--------------------------------------------------------------
# Burger Icon
--------------------------------------------------------------
*/
/* Animation thanks to: callmenick.com, Animating CSS-Only Hamburger Menu-Icons */
.menu-burger {
    display: block;
    //position: absolute;
    position: relative;
    /*overflow:hidden;*/
    z-index:10;
    margin: 0;
    padding: 0;
    width: 44px; // was 48px
    height: 48px;
    //top: 16px;
    //left: 32px; // was: right
    right: 0;
    top: 2px; // small correction
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: 0;
    border: 0;
    cursor: pointer;

    &:focus {
        outline: none;
    }
    span,
    span::before,
    span::after {
        display: block;
        position: absolute;
        height: 2px;
        background-color: $softblack;
        transition: background-color .6s;
    }
    span{
        top: 22px;
        left: 8px;
        right: 8px;
    }
    span::before,
    span::after { 
        left: 0;
        width: 100%;
        content: '';
    }
    span::before {
        top: -7px; //was -8px
    }
    span::after { 
        bottom: -7px; //was -8px
    }
    &.dark-theme {
        span,
        span::before,
        span::after {
            background-color: $wht;
        }
    }
}
@include breakpoint('s') {
    .menu-burger {
        right: 8px;
    }
}
/* Dark-Theme color adaption for burger */
/*.dark-theme {
    .menu-burger {
        span,
        span::before,
        span::after{
            background-color: $wht;
            transition: background-color .3s;
        }
    }
}*/
.menu-burger:hover {
    span,
    span::before,
    span::after{
        background-color: $primary;
    }
}
/* –– To X Animation –– */
.menu-burger-x {
    //background-color: none;
}
/* –– Active state, i.e. menu open –– */
.menu-burger-x.activate {
    //background-color: none;
}
.menu-burger-x.activate span {
    background: none;
}
.menu-burger-x.activate span::before {
    animation: topToX .6s ease-in-out forwards;
}
.menu-burger-x.activate span::after {
    animation: bottomToX .6s ease-in-out forwards;
}
.menu-burger-x.deactivate span::before {
    animation: topToBurger .6s ease-in-out forwards;
}
.menu-burger-x.deactivate span::after {
    animation: bottomToBurger .6s ease-in-out forwards;
}
.menu-burger-x.deactivate span {
    animation: midVisible .6s ease-in-out forwards;
}
.menu-burger-x.is-active {
    span {
        background: none;
    }
    span::before {
        top: 0;
        transform: rotate(45deg);
    }
    span::after { 
        bottom: 0;
        transform: rotate(-45deg);
    }
}
.menu-burger-tiny {
    top:84px !important;
}
@include breakpoint('ml') {
    .menu-burger {
        display:none;
    }
}
/* Burger Top-Line to X */
@keyframes topToX {
    0% {
    }
    50% {
        top: 0;
        transform: rotate(0deg);
    }
    100% {
        top: 0;
        transform: rotate(45deg);
    }
}
/* Burger Bottom-Line to X */
@keyframes bottomToX {
    0% {
    }
    50% {
        bottom: 0;
        transform: rotate(0deg);
    }
    100% {
        bottom: 0;
        transform: rotate(-45deg);
    }
}
/* Burger Top-Line from X to burger */
@keyframes topToBurger {
    0% {
        top: 0;
        transform: rotate(45deg);
    }
    50% {
        top: 0;
        transform: rotate(0deg);
    }
    100% {
        
    }
}
/* Burger Bottom-Line from X to burger */
@keyframes bottomToBurger {
    0% {
        bottom: 0;
        transform: rotate(-45deg);
    }
    50% {
        bottom: 0;
        transform: rotate(0deg);
    }
    100% {
       
    }
}
/* Burger Mid-Line from X to burger */
@keyframes midVisible {
    0% {
        background:none;
    }
    50% {
        background:none;
    }
    100% {
       
    }
}