// section#quote-teaser

/* The stacking of the single slides is currently done with CSS Grid, could also be solved with absolute positioning --> finally check the Browser support of CSS Grid to be sure it has backwards-compatibilty */
.quote-teaser-con {
    position: relative;
}
.quote-teaser {

    &_container {
        padding-top: $leading * 2;
        padding-bottom: $leading;

        display: grid;
        align-items: center; // vertically center (but each grid item is only as large as its content)
    }
    &_quote {

        font-family: 'eb-garamond';
        font-weight: 400;
        font-size: 1.35 * $scale * 1rem;
        line-height: 1.35 * $headlineLineHeight;
        color: $wht;
        //background-color: $primary;
        text-align: center;

        grid-column: 1;
        grid-row: 1;
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.active {
            opacity: 1;
            transition: opacity 1s 1s ease-in-out;
        }
        
        &__source {
            font-size: 0.875 * $scale * 1rem;
            line-height: $leading;
            margin-top: $leading;
        }
    }
}
/* das muss dynamisch hinzugefügt werden, anhand der Anzahl der Einträge */
.indicators {
    margin-top: auto;
    margin-bottom: $gutter * 1.5;
    text-align: center;

    .indicator {
        display: inline-block;
        position: relative;
        width: $gutter * 2;
        height: $gutter * 2;
        margin-left: $gutterHalf / 2;
        margin-right: $gutterHalf / 2;
        opacity: .25;
        transition: opacity 1s ease-in-out;

        &.active {
            opacity: 1;
            transition: opacity 1s 1s ease-in-out;
        }
        span {
            position:absolute;
            left: 0;
            top: $gutter;
            width: 100%;
            height: 2px;
            background-color: $wht;
        }

        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }
}

.slideshow-arrows {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 80%;
    overflow: hidden;

    .arrow {
        position: relative;
        width: 50%;
        height: 100%;
        cursor: pointer;
        /* button resets */
        border: 0;
        border-radius: 0;
        background: transparent;
        padding: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        overflow: hidden;
        outline: none;

        opacity: 0;
        transition: opacity .6s ease-in-out;
    }
}
@include breakpoint('xxs') {
    .quote-teaser {
        &_quote {
            font-size: 1.5 * $scale * 1rem;
            line-height: 1.5 * $headlineLineHeight;
        }
    }
}
@include breakpoint('m'){
    .quote-teaser {

        &_container {
            padding-top: $leading * 2;
            padding-bottom: $leading * 2;
            padding-left: $gutter * 2;
            padding-right: $gutter * 2;
        }
        &_quote {
    
            font-size: 2 * $scale * 1rem;
            line-height: 2 * $headlineLineHeight;
            
            &__source {
                font-size: 1 * $scale * 1rem;
                line-height: $leading;
            }
        }
    }
    .indicators {
    
        .indicator {
            width: $gutter * 2;
        }
    }
    .slideshow-arrows {
        .arrow {
            span {
                position: absolute;
                width: 32px;
                height: 32px;
                //background: rgba($wht,.25);
            }
            &.arrow-prev span {
                left: $gutterHalf;
                border-left: 1px solid $wht;
                border-top: 1px solid $wht;
                transform: rotate(-45deg);
                opacity: 1;
                transition: opacity 1s ease-in-out;
                &.hide {
                    opacity: 0;
                    transition: opacity 1s ease-in-out;
                }  
            }
            &.arrow-next span {
                right: $gutterHalf;
                border-right: 1px solid $wht;
                border-bottom: 1px solid $wht;
                transform: rotate(-45deg);
                opacity: 1;
                transition: opacity 1s ease-in-out;
                &.hide {
                    opacity: 0;
                    transition: opacity 1s ease-in-out;
                }
            }
            &:hover {
                opacity: 1;
            }
        }
    }
}
@include breakpoint('l'){
    .quote-teaser {

        &_container {
            padding-top: $leading * 2;
            padding-bottom: $leading * 2;
            padding-left: $gutter * 3;
            padding-right: $gutter * 3;
        }
        &_quote {
    
            font-size: 2.5 * $scale * 1rem;
            line-height: 2.5 * $headlineLineHeight;
            
            &__source {
                font-size: 1.25 * $scale * 1rem;
                line-height: $leading;
            }
        }
    }
    .indicators {
    
        .indicator {
            width: $gutter * 2.5;
        }
    }
}