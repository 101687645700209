ul.link-list,
ul.dynamic-link-list,
ul.small-link-list {

    font-family: 'eb-garamond';
    font-weight: 400;
    font-size: 1.75 * $scale * 1rem;
    line-height: 1;
    
    list-style: none;
	margin: ($gutter * 2) auto;
    padding-left: 0;
    max-width: 28rem; //484px

    li {

        display: block;
        // as the lines belong to the <a> lines are not translated (looks way better)
        border-bottom: 1px solid rgba($primary,.15);
        //transition: border-color .4s ease-in-out, color .4s ease-in-out;

        a {
            display: block;
            cursor: pointer;

            padding-top: $gutter * 1.35;
            padding-bottom: $gutter * 1.35;
            //padding-left: $gutter;

            line-height: 2rem;

            //color: $blk;

            &:hover {
                //color: $primary;
                //border-bottom: 1px solid $primary;
            }

        }
    }
}
section.dark-theme {
    li {
        border-bottom: 1px solid rgba($wht,.15);

        a {

        }
    }
}

ul.small-link-list {
    font-size: 1.35 * $scale * 1rem;
    max-width: 100%; //484px
    column-count: 1;
    li {
        border-bottom: 0;
        a {
            padding-top: $gutterHalf * 1.5;
            padding-bottom: $gutterHalf * 1.5;
            //padding-left: $gutter;

            line-height: 1.25;
        }
    }
}


@include breakpoint('s') {

    ul.link-list,
    ul.dynamic-link-list {

        font-size: 2 * $scale * 1rem;
        margin: 0 auto;

        li {
            a {
                //line-height: 1.5rem;
                padding-left: $gutter;
            }
        }
    }

    ul.small-link-list {
        font-size: 1.5 * $scale * 1rem;
        //max-width: 100%; //484px
        column-count: 2;
        li {
            border-bottom: 0;
            display: inline-block;
            width: 100%;
            a {
                padding-top: $gutterHalf * 1.5;
                padding-bottom: $gutterHalf * 1.5;
                //padding-left: $gutter;
    
                line-height: 1.25;
            }
        }
    }
}