.topbar {
    background-color: $lightgrey;
    color: rgba($blk,.65);
    min-height: $topBarMinHeight;
    padding: 0 $gutter;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &_content {
        display: block;
        align-items: center;
        max-width: 90%; //$baseWidth;
        height: 100%;
        padding: $gutterHalf 0;
        //margin: 0 auto;
        opacity: 100;
        font-size: 0.875rem;

        &__txt {
            //padding-bottom: $gutterHalf;
            display: inline;
            margin-right: $gutterHalf;
        }

        &__actions {
            display: inline-block;
            flex-shrink: 0;
            margin: 0;
            
            a:last-of-type {
                margin-left: $gutterHalf;
            }
        }
    }

    &_close {
        position: absolute;
        cursor: pointer;
        width: 16px;
        height: 16px;
        top: center;
        right: $gutter;
        //margin-left: auto;
        //margin-right: $gutterHalf;
        //flex-shrink: 0;
        opacity: 100;

        &::before, &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: $primary;
            transition: background-color .6s ease-in-out;
        }
        &::before {
            transform: translateY($gutterHalf) rotate(-45deg) ;
        }
        &::after {
            transform: translateY($gutterHalf) rotate(45deg) ;
        }
        &:hover {
            &::before, &::after {
                background-color: $blk;
            }
        }
    }
    &.topbar_hidden {
        .topbar_content, .topbar_close {
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }
        transition: min-height .4s .3s ease-in-out, height .4s .3s ease-in-out;
        height: 0;
        min-height: 0;
    }
}
// Das muss ich noch umdrehen
@include breakpoint('sm') {
    .topbar {
        justify-content: center;

        &_content {
            display: flex;
            padding: $gutterHalf $gutter;
            margin: 0 auto;
            
            &__txt {
                //padding-bottom: 0;
            }
            &__actions {
                margin: 0 $gutter;
            }
        }
        &_close {
            //margin-top: $gutter;
            right: $gutter * 2;
        }
    }
}