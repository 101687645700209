/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
/*button.button, 
button.button.alt, 
a.button {
    background-color: transparent;
    border: 1px solid $primary;
    border-radius: 8px;
    padding: 14px 24px 15px 24px; 
    color: $primary;
    //display: inline-block;
    font-size: $buttonFontScale * $scale * 100%;
    //line-height: 1.5;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    //overflow-wrap: break-word;
    transition: color 0.8s ease, background-color 0.8s ease;
    &:hover, &:focus, &:active {
        background-color: $primary;
        color: $wht;
    }
}*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
	border: 1px solid $primary;
	border-radius: 4px;
	background: $primary;
	color: $wht;
	font-size: $baseFontSize;
	line-height: 1;
    padding: .8rem 3rem 1rem 3rem;
    margin-top: 1rem;
    font-variant: small-caps;
    letter-spacing: .1rem;
    transition: color 0.8s ease, background-color 0.8s ease;
    &:hover, &:focus, &:active {
        background-color: transparent;
        color: $primary;
    }
    &.half-margin {
        margin-top: $gutterHalf;
    }
    
    &.placeholder {
	    border: 1px solid $crazygreen;
        background: $crazygreen;
        &:hover, &:focus, &:active {
            background-color: transparent;
            color: $crazygreen;
        }
    }
}
button.btn-ghost {
    border: none;
    background: transparent;
    color: $primary;
    padding: 0;
    &:hover, &:focus, &:active {
        //background-color: transparent;
        color: $blk;
    }
}
/* Stylistic Reset */
button.normal {
    font-variant: none;
    letter-spacing: 0;
}
@include breakpoint('m') {
    button {
        &.lowtop {
            margin-top: 0;
        }
    }
}
/*
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	color: $blk;
    border-style: none;
    border-bottom: 1px solid rgba(0,0,0,.1);
    //background: rgba(255,255,255,.25);;
    background: transparent;
    width: 100%;
    padding: 12px 6px 12px;
    transition: background-color .6s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
	color: $blk;
    border-bottom-color: $primary;
    background:#ffffff;
}

select {
	border: 1px solid #ccc;
}

textarea {
	width: 100%;
}*/
/* Material design style animated form labels */

$animation-duration: 0.5s;

@mixin label-active() {
    font-size: 0.875rem;
    transform: translateY(-12px);
}

form {
    margin-top: $gutter * 3;
}
.register-form {
    display: block;
    //overflow: hidden;
    //-moz-transition: max-height 2s ease-out;
    //transition: max-height 2s ease-out;
    height: auto;
}
.form-field {
    display: block;
    margin-bottom: $gutter;

    &.shorter {
        width: 65%;
    }

    &--is-active {
        .form-field__control {
            &::after {
                border-bottom: 2px solid $primary;
                transform-origin: left;
                transform: scaleX(150);
            }
            &.ctrl-select::before {
                background-position: -14px 0;
            }
        }
        .form-field__label {
            color: $primary;
            @include label-active();
        }
    }
    &--is-filled {
        .form-field__label {
          @include label-active();
        }
    }
    &--is-required-error {
        .form-field__control {
            &::after {
                border-bottom: 2px solid $red;
                transform-origin: left;
                transform: scaleX(150);
            }
            &.ctrl-select::before {
                background-position: -28px 0;
            }
        }
        .form-field__label {
            color: $red;
            @include label-active();
        }
    }
}
.form-field__label {
    display: block;
    //font-size: 1rem;
    font-weight: normal;
    color: rgba($blk,.4);
    left: 0;
    margin: 0;
    padding: $gutter $gutter 0;
    position: absolute;
    top: 0;
    transition: all $animation-duration;
    width: 100%;
    &.invalid {
        color: $red;
    }
    &.for-select {
        pointer-events:none;
    }
}
.form-field__control {
    background: $wht;
    border-radius: 2px 2px 0 0;
    overflow: hidden;
    position: relative;
    width: 100%;

    &::after {
        border-bottom: 2px solid $primary;
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        margin: 0 auto;
        position: absolute;
        //right: 0;
        transform-origin: left;
        transform: scaleX(0);
        transition: all $animation-duration;
        width: 1%;
    }
    &.ctrl-select::before {
        content:"";
        position: absolute;
        display: block;
        right: 16px;
        top: 45%;
        width: 12px;
        height: 8px;
        background: url(./assets/img/triangles@2x.png) no-repeat;
        background-size: 42px 8px;
        background-position: 0 0;
        pointer-events:none;
    }
    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        outline: none;
        cursor: pointer;
        border-bottom: 1px solid #e2e2e2;
        //background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
        
        
        &[type="number"] {
            font-variant-numeric: lining-nums;
        }
        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 $blk;
        }
    }
    select::-ms-expand {
        display: none;
    }
}
.form-field__input,
.form-field__textarea {
    appearance: none;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #e2e2e2;
    display: block;
    font-size: 1.2rem;
    margin-top: 24px;
    outline: 0;
    padding: 0 12px 10px 12px;
    width: 100%;
}
.form-field__textarea {
    height: 150px;
}
.form-field__dsgvo {
    font-size: 0.875rem;
    line-height: 1.5;
    margin-bottom: $gutter * 2;
    width: 100%;

    .form-field__checkbox {
    }
}
.form-field__input:invalid {
    border-bottom: 2px solid $red;
}
.dsgvo_errMsg {
    color: $red;
    //font-weight: 600;
}
.form-field-special {
    display: none;
}

// New Radio Buttons – Material design style
$radio-size: 20px;
$radio-checked-size: 8px;
$radio-ripple-size: 14px;

@keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba($primary,.075);
    }
    50% {
        box-shadow: 0px 0px 0px $radio-ripple-size rgba($primary,.25);
    }
    100% {
        box-shadow: 0px 0px 0px $radio-ripple-size rgba($primary,.0);
    }
}
.form-field__radio {
    margin: 16px 0;
    //padding-left: 8px;

    input[type="radio"] {
        display: none;

        &:hover + label {
            color: rgba($blk,.6);
        }
        &:hover + label:before {
            box-shadow: 0px 0px 0px $radio-ripple-size rgba($primary,.075);
        }

        &:checked + label {
            color: rgba($blk,1);
        }

        &:checked + label::before {
            border-color: $primary;
            border-width: 2px;
            animation: ripple .4s linear forwards;
        }
        &:checked + label::after {
            transform: scale(1);
        }
    }

    label {
        display: inline-block;
        min-height: $radio-size;
        position: relative;
        padding: 0 ($radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        color: rgba($blk,.45);
        //opacity: 45%;
        transition: color .4s ease;

        &::before, &::after {
            position: absolute;
            content: '';
            border-radius: 50%;
            transition: all .4s ease;
            transition-property: transform, border-color, border-width;
        }

        &::before {
            left: 0;
            top: 0;
            width: $radio-size;
            height: $radio-size;
            border: 1px solid rgba($blk,.4);
            background-color: $wht;
        }

        &::after {
            left: $radio-size / 2 - $radio-checked-size / 2;
            top: $radio-size / 2 - $radio-checked-size / 2;
            width: $radio-checked-size;
            height: $radio-checked-size;
            transform: scale(0);
            background: $primary;
        }
    }
}
// Custom Checkbox – Material design style
$checkbox-size: 20px;
$checkmark-width: $checkbox-size / 10;
$checkbox-padding: 1.25;

.form-field__dsgvo {
    position: relative;
    margin: 16px 0;
    text-align: left;

    label {
        cursor: pointer;
        display: inline;
        line-height: 1;
        vertical-align: top;
        clear: both;
        padding-left: 1px;
        &:not(:empty) {
            padding-left: $gutterHalf;
        }

        &::before, &::after {
            content: "";
            position: absolute;
            left: 0;
            top: -3px;
        }

        &::before {
            width: $checkbox-size;
            height: $checkbox-size;
            background: $wht;
            border: 1px solid rgba($blk,.4);
            border-radius: 4px;
            cursor: pointer;
            transition: background .4s;
        }

        &::after {
            // checkmark
        }
        a {
            vertical-align: inherit;
        }
    }

    input[type="checkbox"] {
        outline: 0;
        visibility: hidden;
        width: $checkbox-size;
        margin: 0;
        display: block;
        float: left;
        font-size: inherit;

        &:checked {
            + label:before {
                background: $primary;
                border: none;
            }

            + label:after {
                $checkmark-size: $checkbox-size / 2 * $checkbox-padding;
                //$checkmark-size: 12px;
                transform: translate(2 + $checkbox-padding + px, ($checkbox-size / 2) - ($checkmark-size / 2.6)) rotate(-45deg);
                width: $checkmark-size;
                height: $checkmark-size / 2;
                border: $checkmark-width solid $wht;
                border-top-style: none;
                border-right-style: none;
            }
        }
    }

}

// Response-Animation (form div replacement)

.animate-response {
    animation: fadeIn .5s ease-in-out forwards;
}
.hide-form-intro {
    animation: fadeOut .5s ease-in-out forwards;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        height: 300px;
    }
}
@keyframes fadeOut {
    to {
        opacity: 0;
        height: 0px;
    }
}

@include breakpoint('l') {
    .form-field {
        &.shorter {
            width: 30%;
        }
    }
    .form-field__dsgvo {
        width: 80%;
    }
}


