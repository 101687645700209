/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
// Webfonts
// basePath (relative to compiled style.css!)
$fontBasePath: "assets/fonts/";
// @params: name, path, weight (normal(400), bold(700), 500, 700 etc.), style (normal, italic)
// Headings
@include font-face("eb-garamond", $fontBasePath + "eb-garamond-v12-latin/eb-garamond-v12-latin-regular", 400, normal);
// Copy
@include font-face("meta-w05", $fontBasePath + "meta-w05/meta-w05-regular", 400, normal, woff2 woff);
@include font-face("meta-w05", $fontBasePath + "meta-w05/meta-w05-italic", 400, italic, woff2 woff);
@include font-face("meta-w05", $fontBasePath + "meta-w05/meta-w05-medium", 600, normal, woff2 woff);
@include font-face("meta-w05", $fontBasePath + "meta-w05/meta-w05-medium-italic", 600, italic, woff2 woff);

// Copy Regular
//@include font-face("Source Sans Pro", $fontBasePath + "source-sans-pro-v12-latin-regular", normal, normal);
// Copy Italic
//@include font-face("Source Sans Pro", $fontBasePath + "source-sans-pro-v12-latin-italic", normal, italic);
// Copy Semibold
//@include font-face("Source Sans Pro", $fontBasePath + "source-sans-pro-v12-latin-600", bold, normal);
// Copy Semibold Italic
//@include font-face("Source Sans Pro", $fontBasePath + "source-sans-pro-v12-latin-600italic", bold, italic);
// from typebase.css (Github)
html {
    /* Change default typefaces here */
    font-family: meta-w05, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: $baseFontSize / 16 * 100%;
    color: $blk;
    // Make everything look a little nicer in webkit
    -webkit-font-smoothing: antialiased; // smoother good for negative text as well, 'subpixel-antialiased' gives sharper text - TRY!
    -moz-osx-font-smoothing: grayscale;
    // -webkit-text-size-adjust: auto
    // -moz-text-size-adjust: auto
    // -ms-text-size-adjust: auto
    // -o-text-size-adjust: auto
    // text-size-adjust: auto

}
body {
    //font-size: ($baseFontSize * 1.125) / 16 * 100%; //18pt
    font-size: $baseFontSize / 16 * 100%;
}
/* Correction for Meta Pro Bold copy text */
b, strong {
    font-weight: 600;
    //font-size: 15.8px // renders better (firefox) - warum auch immer...der 16pt Text in Medium sieht zu groß aus.
}
/* Copy & Lists */

p {
    /* Try to set max-width from breakpoint M upwards */
    //max-width: $smallPWidth;
    line-height: $leading;
    margin-top: $leading;
    margin-bottom: 0;
    //margin-left: $gutterHalf;
    //margin-right: $gutterHalf;
    hyphens: manual;

    &.extra-bottom {
        margin-bottom: $leading;
    }
    &.low-top {
        margin-top: 0;
    }
    /*&.medium-wide {
        max-width: $mediumPWidth;
    }
    &.wide {
        max-width: $pWidth;
    }*/
    &.limit {
        max-width: $smallPWidth;
    }
    &.centered {
        text-align: center;
    }
    &.quote {
        &:before {
            display: block;
            content: "";
            width: $colWidth;
            border-top: 1px solid;
            padding-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
ul,
ol {
    margin-top: $leading;
    margin-bottom: $leading;
    li {
        line-height: $leading;
    }
    ul,
    ol {
        margin-top: 0;
        margin-bottom: 0;
    }
}
// actually nonsense to use an extra class for a default element...
main ul.standard {
    list-style-type: none;
    padding-left: $gutter;
    li {
        padding-left: $gutter;
        &::before {
            content: "·";
            display: inline-block;
            margin-left: -$gutter;
            width: $gutter;
        }
    }
    // longer li elements need extra vertical distance, except the last
    &.double-distance {
        li {
            padding-bottom: $leading;
            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }
}
blockquote {
    line-height: $leading;
    margin-top: $leading;
    margin-bottom: $leading;
}
/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    /* try to set max-width from breakpoint M upwards only */
    //max-width: $smallPWidth;
    font-family: 'eb-garamond';
    font-weight: 400;
    //font-family: serif;
    line-height: $headlineLineHeight;
    margin-top: $leading;
    margin-bottom: $leading;
    //margin-left: $gutterHalf;
    //margin-right: $gutterHalf;
    /*&.wide {
        max-width: $mediumPWidth;
    }
    &.medium-wide {
        max-width: $mediumPWidth;
    }*/
    &.limit {
        max-width: $smallPWidth;
    }
}
h1 {
    font-size: 3 * $scale * 1rem;
    line-height: 3 * $headlineLineHeight;
    margin-top: 2 * $leading;
}
h2 {
    font-size: 2 * $scale * 1rem;
    line-height: 2 * $headlineLineHeight;
    margin-top: 1.25 * $leading;
    &.semantic-h3 {
        font-size: 2 * $scale * 1rem;
        line-height: 2 * $headlineLineHeight;
        margin-top: 1 * $leading;
    }
    &.hero-h2 {
        font-size: 1.6875 * $scale * 1rem;
        line-height: 1.6875 * $headlineLineHeight;
    }
    &.testhead {
        margin-top: 2rem !important;
    }
}
h3 {
    font-size: 1.5 * $scale * 1rem;
    line-height: 1.5 * $headlineLineHeight;
}
h4 {
    //font-size: 1.3125 * $scale * 1rem;
    font-size: 1.25 * $scale * 1rem;
    line-height: 1.25 * $headlineLineHeight;
}
h5 {
    font-size: $scale * 1rem;
}
h6 {
    font-size: $scale / 2 * 1rem;
}
/* Tables */

table {
    margin-top: $leading;
    border-spacing: 0px;
    border-collapse: collapse;
}
td,
th {
    padding: 0;
    line-height: $baseLineHeight * $baseFontSize - 0px;
}
/* Code blocks */

code {
    // Forces text to constrain to the line-height. Not ideal, but works.
    vertical-align: bottom;
}
/* Topline for h2 headline */
.topline {
    //margin-bottom: $leading + $gutter;
    &:before {
        display: block;
        content: "";
        width: $colWidth;
        border-top: 2px solid $primary;
        padding-bottom: 2rem;
    }
    &.hidden-on-mobile {
        &:before {
            display: none;
        }
    }
}
/* Topline alternative as single element */
.theline {
    width: $colWidth;
    border-top: 2px solid $primary;
    margin-top: 1.5 * $leading;

    &.hidden-on-mobile {
        display: none;
    }
    &.low-top {
        margin-top: $leading;
    }
}
/* Leading paragraph text */
.lead {
    font-size: $scale * 1rem;
}
/* Hug a the block above you */

.hug {
    margin-top: 0;
}
.smaller {
    font-size: 0.875rem;
    line-height: 1.5;
}
.smaller875_relative {
    font-size: 0.875em;
}
.smaller75_relative {
    font-size: 0.75em;
}
.bigger {
    font-size: 1.2rem;
}
.caps {
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: 0.05em;
}
.bold {
    font-weight: 600;
}
.bolder {
    font-weight: 700;
}
.sideinfo {
    color: rgba($blk,.5);
}
/* should be a separate module as is too specific */
.countup-group {
    text-align: center;
    h1, p {
        margin-left: auto;
        margin-right: auto;
    }
}
.countup-number {
    display: inline-block;
    text-align: right;
}

::selection {
    background: rgba( $primary, .25);
}
@include breakpoint('xxs'){
    h2 {
        font-size: 2.5 * $scale * 1rem;
        line-height: 2.5 * $headlineLineHeight;
        margin-top: 1.5 * $leading;
    }
}
@include breakpoint('s'){

    body {
        font-size: ($baseFontSize * 1.125) / 16 * 100%; //20pt
    }
    p {
        line-height: 1.25 * $leading;
    }
    main ul.standard {
        li {
            line-height: 1.25 * $leading;
        }
    }
    /* Correction for Meta Pro Bold copy text */
    b, strong {
        font-weight: 600;
        //font-size: 18px; // renders better (firefox) - warum auch immer...der 16pt Text in Medium sieht zu groß aus.
    }

    h1, h2, h3, h4, h5, h6 
    {
    /*overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;*/
    }
    h1 {
        font-size: 3.5 * $scale * 1rem;
        line-height: 3.5 * $headlineLineHeight;
        margin-top: 2 * $leading;
    }
    h2, h2.hero-h2 {
        font-size: 2.5 * $scale * 1rem;
        line-height: 2.5 * $headlineLineHeight;
        margin-top: 2 * $leading;
        &.semantic-h3 {
            font-size: 2.25 * $scale * 1rem;
            line-height: 2.25 * $headlineLineHeight;
            margin-top: 1 * $leading;
        }
        &.low-top {
            margin-top: 0 !important;
        }
    }
    h3 {
        font-size: 2 * $scale * 1rem;
        line-height: 2 * $headlineLineHeight;
    }
    h4 {
        //font-size: 1.3125 * $scale * 1rem;
        font-size: 1.5 * $scale * 1rem;
        line-height: 1.5 * $headlineLineHeight;
    }
    h5 {
        font-size: $scale * 1rem;
    }
    h6 {
        font-size: $scale / 2 * 1rem;
    }
    .topline, .topline.hidden-on-mobile {
        //margin-bottom: $leading + $gutter;
        margin-bottom: $leading;
        &:before {
            display: block;
            content: "";
            width: (2 * $colWidth) + $gutter;
            border-top: 2px solid $primary;
            padding-bottom: 2rem;
        }
    }
    /* Topline alternative as single element */
    .theline, .theline.hidden-on-mobile {
        width: (2 * $colWidth) + $gutter;
        margin-top: 2 * $leading;

        &.low-top {
            margin-top: 0;
        }
    }
}
@include breakpoint('m'){
    p {
        max-width: $smallPWidth;
        
        &.medium-wide {
            max-width: $mediumPWidth;
        }
        &.wide {
            max-width: $pWidth;
        }
    }
    h1, h2, h3, h4, h5, h6 {
        max-width: $smallPWidth;
    
        &.wide,
        &.medium-wide,
        &.stay-wide {
            max-width: $mediumPWidth;
        }
    }   
    /* Der Sonderfall: Links auf der Sozietät-Seite */
    .aligned-links {
        float: right;
        line-height: 1.525; // warum auch immer...
    }
    .countup-group {
        text-align: inherit;
        h1, p {
            margin-left: inherit;
            margin-right: inherit;
        }
    }
}
@include breakpoint('l'){

    body {
        font-size: ($baseFontSize * 1.25) / 16 * 100%; //20pt
    }
    p {
        line-height: 1.33333333 * $leading;
    }
    main ul.standard {
        li {
            line-height: 1.33333333 * $leading;
        }
    }
    /* Correction for Meta Pro Bold copy text */
    b, strong {
        font-weight: 600;
        //font-size: 20px; // renders better (firefox) - warum auch immer...der 16pt Text in Medium sieht zu groß aus.
    }

    h1, h2, h3, h4, h5, h6 
    {
        &.stay-wide {
            max-width: $pWidth;
        }
    /*overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;*/
    }
    h1 {
        font-size: 4 * $scale * 1rem;
        line-height: 4 * $headlineLineHeight;
        margin-top: 2 * $leading;
    }
    h2, h2.hero-h2 {
        font-size: 3 * $scale * 1rem;
        line-height: 3 * $headlineLineHeight;
        margin-top: 2 * $leading;
        &.semantic-h3 {
            font-size: 3 * $scale * 1rem;
            line-height: 3 * $headlineLineHeight;
            margin-top: 2 * $leading;
        }
    }
    h3 {
        font-size: 2 * $scale * 1rem;
        line-height: 2 * $headlineLineHeight;
    }
    h4 {
        //font-size: 1.3125 * $scale * 1rem;
        font-size: 1.5 * $scale * 1rem;
        line-height: 1.5 * $headlineLineHeight;
    }
    h5 {
        font-size: $scale * 1rem;
    }
    h6 {
        font-size: $scale / 2 * 1rem;
    }
    .topline, .topline.hidden-on-mobile {
        //margin-bottom: $leading + $gutter;
        margin-bottom: $leading;
        &:before {
            display: block;
            content: "";
            width: (2 * $colWidth) + $gutter;
            border-top: 2px solid $primary;
            padding-bottom: 2rem;
        }
    }
}