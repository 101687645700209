/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
html {
    box-sizing: border-box;
    overflow-x: hidden;
	@if $smoothScroll == true {
		scroll-behavior: smooth;
	}
}
/* for people with motion sickness check a setting in the Reduced Motion API and turn off smooth scrolling */
@media screen and (prefers-reduced-motion: reduce){
	html{
		scroll-behavior: auto;
	}
}
*,
*:before,
*:after {
	/* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

body {
    background: #fff;
    overflow-x: hidden;
	/* Fallback for when there is no custom background color defined. */
}

hr {
	background-color: rgba($blk,.15);
	border: 0;
	height: 1px;
    margin-bottom: 1.5em;
    &.separator {
        margin: ($gutter * 4) 0;
    }
}

ul, ol {
	margin: 0 0 1.5em 3em;
}

ul {
    list-style-type: disc;
    margin-left: $gutter;
    margin-top: $leading;
    //max-width: $pWidth - $gutter;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 1.5em 1.5em;
}

img {
	height: auto;
	/* Make sure images are scaled correctly. */
	max-width: 100%;
    /* Adhere to container width. */
}

figure {
	margin: 1em 0;
	/* Extra wide images within figure tags don't overflow the content area. */
}

table {
	margin: 0 0 1.5em;
	width: 100%;
}

section {
    margin-left: $gutterHalf;
    margin-right: $gutterHalf;

    .hide-overflow {
        overflow: hidden;
    }
}