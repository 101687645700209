// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================
// Automatically generates @font-face css
// usage:
// @include font-face(Samplino, fonts/Samplino);
// @include font-face("Samplina Neue", fonts/SamplinaNeue, bold, italic);
// @include font-face(Samplinoff, fonts/Samplinoff, null, null, woff); –– only WOFF
// @include font-face(Samplinal, fonts/Samplinal, 500, normal, eot woff2 woff); –– normal, weight 500, 3 formats

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}
// =============================================================================
// Breakpoint Mixin
// =============================================================================
/*
$breakpoint = required size, can be defaults (s,m...) or required size (with unit, unitless defaults to 'px')
$direction = $mediaDirection from the settings _vars
*/
@mixin breakpoint($breakpoint, $direction: $mediaDirection) {
    // check if value is contained in SASS-Map
    @if map-has-key($breakpoints, $breakpoint) {
        @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    }
    // if a numerical value was provided it gets unit checked
    @else if unitless($breakpoint) {
        $breakpoint: $breakpoint + 0px;
        @media (#{$direction}-width: $breakpoint) {
            @content;
        }
    }   
}