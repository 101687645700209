
.flex-gallery {
    margin-bottom: 3rem;
    padding-bottom: $gutter;
    //height: auto;
    display: flex;

    // scroll in x-direction
    overflow-x: auto;
    // no scrolling in y-direction
    overflow-y: hidden;
    scroll-snap-type: x mandatory; // proximity - mandatory
    //scrollbar-width: none; // hides scrollbar on Firefox
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch; // non-standard


    .flex-image {
        scroll-snap-align: start; // start – end – center
        margin: $gutterHalf;
        flex-shrink: 0;
        width: 80%;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    // FÜR TABLET NOCH EINE SAUBERE LÖSUNG FINDEN
    @include breakpoint('s') {
        max-width: 100%;
        overflow-x: hidden;
        scroll-snap-type: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: none; // non-standard

        &.align-top {
            align-items: flex-start;
        }
        
        .flex-image {
            scroll-snap-align: none; // start – end – center
            flex-shrink: 1;
            width: auto;
        }
    }

}