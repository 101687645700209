ul.accordion {

    list-style: none;
	margin: ($gutter * 2) auto;
    padding-left: 0;
    //max-width: 30rem; //480px
    
    border-top: 1px solid rgba($primary,.25);

    li {
        
        // lines belong to <li> elements
        border-bottom: 1px solid rgba($primary,.25);

        a.accordion_title {

            //display: block;
            display: flex;
            align-items: baseline;
            cursor: pointer;

            padding-top: $gutter * 1.35;
            padding-bottom: $gutter * 1.35;
            //padding-left: $gutter;
            
            font-family: 'eb-garamond';
            font-weight: 400;
            font-size: 1.35 * $scale * 1rem;
            line-height: 1;

            //transition: border-color .4s ease-in-out, color .4s ease-in-out;
            span.accordion_icon {
                display: block;
                position: relative;
                width: 18px;
                height: 18px;
                left: auto;
                top: 12px;
                margin-left: auto;
                float: right;

                &::before, &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    background-color: $primary;
                    
                }
                &::before {
                    opacity: 100;
                    transition: background-color .6s, opacity .4s ease-in-out;
                }

                &::after {
                    transform: rotate(90deg);
                    transition: background-color .6s, transform .4s ease-in-out;
                }
            }
            
    
            &:hover {
                span.accordion_icon {
                    &::before, &::after {
                        background-color: $blk;
                    }
                }
            }

            &.is-active {
                color: $blk;
                span.accordion_icon {
                    &::before, &::after {
                        background-color: $blk;
                    }
                    &::before {
                        opacity: 0;
                    }
                    &::after {
                        //width: 0;
                        //opacity: 0;
                        transform: rotate(0deg);
                        transition: .4s ease-in-out;
                        transition-property: transform;
                    }
                }
            }
        }

        div.accordion_content {
            height: 0;
            overflow: hidden;
            transition: height .6s ease-in-out;
            transition-duration: .6s;
            padding-left: 0;

            p, ul {
                opacity: 0;
                transition: opacity .3s ease-in-out;
                margin-top: 0;
                padding-bottom: $gutter * 1.35;
                
                &.no-bottom {
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }

            ul {
                margin-left: 0;
                padding-left: 0;

                li {
                    border-bottom: 0;
                }
            }

            &.is-visible {

                p, ul {
                    opacity: 100;
                    transition: opacity .3s .3s ease-in-out;
                }
            }
        }
    }
    // Dark Theme Color Mods
    &.dark-theme {

        border-top-color: rgba($wht,.15);

        li {
            // lines belong to <li> elements
            border-bottom-color: rgba($wht,.15);

            a.accordion_title {
                span.accordion_icon {
                    &::before, &::after {
                        background-color: $wht;
                    }
                }
                &:hover {
                    span.accordion_icon {
                        &::before, &::after {
                            background-color: $primary;
                        }
                    }
                }
                &.is-active {
                    color: $primary;
                    span.accordion_icon {
                        &::before, &::after {
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }
}
@include breakpoint('xxs') {

    ul.accordion {

        li {
            a.accordion_title {
                font-size: 1.55 * $scale * 1rem;

                span.accordion_icon {
                    width: 24px;
                    height: 24px;
                    top: 15px;
                }
            }
            div.accordion_content {
                padding-left: $gutter;
            }
        }

    }
}
@include breakpoint('xs') {

    ul.accordion {

        li {
            a.accordion_title {
                font-size: 1.75 * $scale * 1rem;
            }
        }
    }
}
@include breakpoint('s') {

    ul.accordion {
        margin: 0 auto;

        &.extra-margin {
            margin: ($gutter * 4) auto; 
        }

        &.align-left {
            margin: 0 0;
        }

        li {
            a.accordion_title {
                font-size: 2 * $scale * 1rem;
            }
        }

    }
}
@include breakpoint('m') {

    ul.accordion {
        max-width: 30rem;

        &.extra-margin {
            margin: ($gutter * 3) auto; 
        }
    }
}