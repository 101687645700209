.layer-reveal,
.layer-reveal-solo {

    &_content {
        height: 0;
        overflow: hidden;
        transition: height .6s ease-in-out;
        transition-duration: .6s;
        //padding-left: $gutter;

        &.no-padding {
            padding-left: 0;
        }
        
        .social-icon {
            width: 20px;
            height: 20px;
            margin-left: $gutterHalf;
            vertical-align: middle;
        }

        .content-block {
            margin-top: $gutterHalf;
        }

        &__inner {
            margin-top: $gutter * 2;
            
            &.lowtop {
                margin-top: 0;
            }
        }
        
        p {
            opacity: 0;
            transition: opacity .3s ease-in-out;
            margin-top: 0;
            padding-bottom: $leading;    
        }

        &.is-visible {

            p {
                opacity: 1;
                transition: opacity .3s .3s ease-in-out;
            }
        }
            
    }
    &_open {
        opacity: 1;
        transition: opacity .6s .3s ease-in-out;

        &.fade-out {
            opacity: 0;
            transition: opacity .3s ease-in-out;
            pointer-events: none;
        }
    }
}
@include breakpoint('s') {
    .layer-reveal,
    .layer-reveal-solo {
    
        &_content {
            p {
                padding-bottom: 1.25 * $leading;
            }
        }
    }
}
@include breakpoint('sm') {
    .layer-reveal,
    .layer-reveal-solo {
    
        &_content {
            padding-left: $gutter;
        }
    }
}
@include breakpoint('l') {
    .layer-reveal,
    .layer-reveal-solo {
    
        &_content {
            p {
                padding-bottom: 1.3333333 * $leading;
            }
        }
    }
}