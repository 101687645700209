/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {
    //color: rgba( $blk, .75);
    color: $primary;
    text-decoration: none;
    /*border-bottom: 1px solid rgba( $blk, .15);
    padding-bottom: 4px;*/
    transition: color .6s;
    //transition: color .3s, border-color .3s;
}
main p a, main ul.standard a {
    border-bottom: 1px solid rgba( $primary, .25);
    padding-bottom: 1px;
	transition: color .6s, border-color .6s;
}

h1,h2,h3,h4,h5 {
    a {
        color: $blk;
    }
    a:hover, a:active {
        color: $primary;
    }
}

a:visited {
    //color: $primary;
    //border-color: $blk;
}

a:hover, a:active { // I have removed a:focus because of the accordion title
    //color: $primary;
    color: $blk;
    border-color: rgba($blk, .5);
    transition: color .4s, border-color .4s;
    //
}

a:focus {
	outline: 0; //thin dotted
}

a:hover, a:active {
	outline: 0;
}
/*--------------------------------------------------------------
## Calendar Button
--------------------------------------------------------------*/
.btn-ghost {
    .btn-ghost__arrow {
        display: inline-block;
        transition: transform .3s ease-in-out;
        transform-origin: center;

        &.is-active {
            transform: rotate(90deg) translate(3px,-2px);
        }
    }
}
.calendar-con {
    
    margin-top: $gutter;

    .calendar-menu {
        height: 148px;
        opacity: 100;
        overflow: hidden;
        transition: height .6s ease-in-out, opacity .6s ease-in-out .2s;

        &.is-hidden {
            height: 0;
            opacity: 0;
            transition: height .6s ease-in-out .2s, opacity .6s ease-in-out;

            ul {
                li {
                    transform: translate(-384px, 0);
                }
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 1rem 1rem 2.75rem;

            a {
                display: block;
                cursor: pointer;
                // as the lines belong to the <a> lines are not translated (looks way better)
                border-bottom: 1px solid rgba($blk,.15);
                
                li {
                    padding-top: $gutterHalf;
                    padding-bottom: $gutterHalf;
                    transition: transform .6s ease-in-out;
                    
                    @include breakpoint('l') {
                        font-size: 1rem;
                    }
                }
                // staggering
                &:nth-child(2) {
                    li {
                        transition-delay: .1s;
                    }    
                }
                &:nth-child(3) {
                    li {
                        transition-delay: .2s;
                    }
                    
                }
            }
            
        }
    }
}
/*--------------------------------------------------------------
## Navigation (Main, Mobile)
--------------------------------------------------------------*/
.navigation-con {
    margin-top: $gutter;
}
.mobile-menu-con {
    display: none;
}
.main-navigation,
.mobile-navigation {
	clear: both;
	display: block;
	float: left;
	width: 100%;
	font-size: 1rem;//$menuFontScale * $scale * 100%;
	font-weight: 600;
	text-transform: uppercase;
    letter-spacing: 0.25em;
    
    &.dark-theme,
    &.light-theme,
    &.primary-theme {
        background: transparent;
    }
}
.main-navigation {
    display: none;
}
/* Increase the mobile menu font size */
.mobile-navigation {
    font-size: $menuFontScale * $scale * 120%;
}

.main-navigation ul,
.mobile-navigation ul {
	list-style: none;
	margin: 0;
	padding-left: 0;
}
.main-navigation ul {
    //display: none;
    max-width: none;
    position: relative;

    hr {
        display: none;
    }
}

.main-navigation li {
	float: left;
    position: relative;
	
    a {
        padding: ($gutter * 1.5) $gutter;
    }
}
.main-navigation li.menu-item-ls {

    a {
        padding: ($gutter * 1.5) ($gutter * 1.6);
        color: $primary;
        //background-color: $lightgrey;
        background-color: rgba($blk, 0.05); // to make it work on the new vorträge/events page as well
        margin-left: $gutterHalf;
        transition: .6s ease-in-out;
        transition-property: background-color, color;

        div {
            display: inline-block;
            width: 1.8em;
            overflow: hidden;
            white-space: nowrap;

            span:nth-child(1) {
                display: inline-block;
                margin-left: 1px;
                transition: .6s ease-in-out;
                transition-property: margin-left, opacity;
            }
            span:nth-child(2) {
                display: inline-block;
                opacity: 0;
                transition: opacity .6s ease-in-out;
            }
        }

        &:hover {
            color: $wht;
            background-color: $primary;
            transition: .4s ease-in-out;

            div {
                span:nth-child(1) {
                    margin-left: -1.5em;
                    transition: .4s ease-in-out;
                    opacity: 0;
                }
                span:nth-child(2) {
                    opacity: 100;
                    transition: opacity .4s ease-in-out;
                }
            }
        }
    }
}
.main-navigation {

    &.dark-theme {
        
        li.menu-item-ls {

            a {
                background-color: $darkblue2;

                &:hover {
                    background-color: $primary;
                }
            }
        }
    }
    &.bg-transp {
        
        li.menu-item-ls {

            a {
                background-color: rgba($wht,.15);

                &:hover {
                    background-color: rgba($primary,1);
                }
            }
        }
    }
}

.main-navigation,
.mobile-navigation {
    a {
        display: block;
        color: $blk;
        text-decoration: none;
        border: 0;
        //padding-bottom: 0;
        transition: color .3s;

        &:hover, &:focus, &:active
        {
            color: $primary;
        }
        &.active {
            color: $primary;
        }
    }
}
.main-navigation.toggled {
    display:block;
    position:absolute;
    //top:$headerHeight;
    z-index:9;
    top: 0;
    left:0;
    right:0;
    height: 100vh;
    padding-top: $headerHeight; // was: + 32px
    padding-bottom: 32px;
    //border-bottom: 6px solid #E94F1D;
    background: rgba($wht,1);
    text-align: center;
    &.dark-theme,
    &.primary-theme {
        background: rgba($darkblue,1);
    }
    &.on-site-fixed {
        position: fixed;
        //top: $headerHeight;
    }
}
.menu-toggle,
.main-navigation.toggled ul {
    display: block;
}
.main-navigation.toggled li {
    float: none;
    display:block;
    // the <a> inside the <li> already has default padding
    //padding: $gutterHalf 0 $gutterHalf 0;
}
@include breakpoint('s') {
    .navigation-con {
        margin-top: 0;
        margin-left: auto; // pushes the navigation-con flex item to the right
    }
}

@include breakpoint('ml') {
    .main-navigation {
        display: block;
        margin-top: -$gutter;

        ul {
            //display: none;
            max-width: none;
            position: relative;
        
            hr {
                //display: none;
                display: block;
                position: absolute;
                top: $leading + ($gutter * 2);
                left: 0;
                height: 2px;
                width: 0;
                margin: 0;
                background: $primary;
                border: none;
                pointer-events:none;
                transition: .4s ease-in-out;
                transition-property: width;
        
                &.sliding {
                    transition-property: left, width;
                }
            }
        }
    }
    // was bedeutet das?
    @-moz-document url-prefix() {
        .main-navigation ul hr {
            float: left;
        }
    }
    .menu-toggle {
		display: none;
    }
    .menu-burger {
		display: none;
	}
    
}/*
@include breakpoint('s') {
    .mobile-menu-con.toggled {
        padding-top: $headerHeight_xs + 32px;
    }
}*/
/*--------------------------------------------------------------
## Footer Navigation
--------------------------------------------------------------*/
/*.footer-navigation {
	font-size: $menuFontScale * $scale * 100%;
	line-height: 1.5;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.125em;
}
.footer-navigation ul {
	padding: 0;
	margin: 0;
	list-style: none;
    columns: 2 160px;
    column-gap: $gutter;
}
.footer-navigation a {
    border: 0;
}*/