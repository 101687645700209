.image-grid {
    position: relative;
    width: 100%;
    height: 100vh; // was: auto (but due to the animation auto led to a flash of the next content until height was set
    margin-top: $gutter * 2;
    // scroll in x-direction
    overflow-x: auto;
    // no scrolling in y-direction
    overflow-y: hidden;
    scroll-snap-type: x mandatory; // proximity - mandatory
    //scrollbar-width: none; // hides scrollbar on Firefox
    scrollbar-width: thin;
    // DEsign the scrollbar on Webkit, Edge, iOS, Opera, Chrome (pretty good)
    &::-webkit-scrollbar {
        // hides scrollbar on webkit
        //display: none;
        height: 2px;
        margin-top: $gutter;
    }
    &::-webkit-scrollbar-thumb {
        height: 2px;
        //border: 30px solid rgba(255,255,255,0);
        //background-clip: padding-box;
        border-radius: 0;
        background-color: $primary;
    }
    &::-webkit-scrollbar-track {
        //height: 2px;
        //margin-top: 30px;
        background: $lightgrey;
    }
    -webkit-overflow-scrolling: touch; // non-standard

    // IE10 -- maybe this needs to be first
    /*-ms-overflow-style: none;
    -ms-scroll-chaining: none;
    -ms-scroll-snap-type: mandatory;
    -ms-scroll-snap-points-x: snapInterval(0%, 80%);*/

    //margin-bottom: 120px;
    //margin-top: 32px;
    //background-color: $lightgrey;
    transition: height .6s ease-in-out;

    &.disable {
        transition: none;
    }

    &_con {
        position: absolute;
        cursor: pointer;
        scroll-snap-align: start; // start – end – center

        transition: .6s ease-in-out; // the transition for resize changes
        transition-property: left, top, width, height;

        &.disable {
            transition: none;
        }

        &__anim {
            width: 100%;
            height: 100%;
        }

        &__img {
            width: 100%;
            height: 100%;
            background-size: cover;
            //background-size: 300px 300px;
            background-repeat: no-repeat;
            background-position: center;
            //background-position: center top;
            
            /* Crispier (downscaled) images on chrome */
            image-rendering: -webkit-optimize-contrast;
            
            &.is-landscape {
                background-position-x: center;
                background-position-y: 20%;
                //background-position-y: top;

                &.is-face-set {
                    background-position-y: 10%;
                    //background-position-y: top;
                }
            }
        }
        &__name {
            width: 100%;
            //height: 30%;
            height: 80px;
            padding-top: $gutter;
            h4 {
                margin-top: 0;
                margin-bottom: 0;
            }
            a {
                display: none;
            }
        }
    }
    // the "blind" element used as a transition-end callback element
    &_animTracker {
        position: absolute;
        width: 1px;
        height: 1px;
        top: 0;
        left: 0;
        background: transparent;
        transition: left .6s ease-in-out;
    }
}
// Trigger-Button is hidden on mobile
a#trigger-collapse {
    display: none;
}
@include breakpoint('smm') {

    .image-grid {
        margin-top: 0;
        // no scrolling in x-direction
        overflow-x: visible;
        // no scrolling in y-direction
        overflow-y: visible;
        scroll-snap-type: none; // proximity - mandatory
        -webkit-overflow-scrolling: auto; // non-standard
        scrollbar-width: none; // hides scrollbar on Firefox
        // DEsign the scrollbar on Webkit, Edge, iOS, Opera, Chrome (pretty good)
        &::-webkit-scrollbar {
            // hides scrollbar on webkit
            display: none;
            height: 0;
            margin-top: 0;
        }
        &::-webkit-scrollbar-thumb {
            display: none;
            height: 0;
        }
        &::-webkit-scrollbar-track {
            display: none;
            height: 0;
        }

        &_con {
            overflow: hidden;
            scroll-snap-align: none; // start – end – center

            &__img {
                transform: scale(1);
                transition: transform .6s ease-in-out; 
            }
            &__name {
                height: 64px; // war 120 px // war 60 px
                //background-color: $primary; 
                opacity: 0;
                transition: .5s .1s ease-in-out;
                transition-property: transform, opacity;
                padding: $gutter;
                padding-left: $gutterHalf;   
                padding-right: 0;
                padding-top: $gutterHalf; 
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                h4 {
                    font-size: 1.25 * $scale * 1rem;
                    line-height: 1.15;
                    margin-bottom: 2px;
                }

                a {
                    font-size: $baseFontSize * 0.75pt; // was: 0.875pt
                    line-height: 1;
                    font-variant: small-caps;
                    letter-spacing: .1rem;
                    width: 100%;
                    display: block;
                    margin-top: auto;
                    margin-bottom: $gutterHalf;
                }
            }
            &.hover:hover {
                .image-grid_con__img {
                    transform: scale(1.15);
                }
                .image-grid_con__name {
                    transform: translate(0, -100%);
                    opacity: 1; // was 0.9
    
                }
            }
        }
        // Just as a placeholder for now
        &_close-btn {
            position: absolute;
            opacity: 1;
            top: 0;
            right: 0;
            //width: 64px;
            //height: 64px;
            //background-color: $lightgrey;
            transition: opacity .4s ease-in;

            &.hidden {
                opacity: 0;
            }
            &.disabled {
                display: none;
            }
        }
    }
    a#trigger-collapse {
        display: block;
    }
}