/*--------------------------------------------------------------
# TYPESETTING VARS
--------------------------------------------------------------*/
// Edit here or override in main file prior to import of this.
$baseFontSize: 16 !default; // in pixels. This would result in 18px on desktop
$baseLineHeight: 1.5 !default; // how large the line height is as a multiple of font size // was: 1.6875
$headlineLineHeight: 1.125rem;
$leading: $baseLineHeight * 1rem !default;
// Rate of growth for headings
// I actually like this to be slightly smaller then the leading. Makes for tight headings.
$scale: 1 !default; // im Original 1.414
$menuFontScale: (1/1.125);
$buttonFontScale: (1/1.25);
/*--------------------------------------------------------------
# LAYOUT VARS
--------------------------------------------------------------*/
// Mainly used in _layouts
$contentWidth: 74rem; // 16px * 74 = 1184px
$baseWidth: 86.5rem; // 16px * 86 = 1384px –- used as content max-width for header and footer
$pWidth: 49rem; // 16px * 49 = 784px
$mediumPWidth: 36rem;
$smallPWidth: 30rem; // 16px * 30 = 484px – good for 1/2 – 1/2 cols
$gutter: 1rem; // 16px
$gutterHalf: $gutter/2; // 8px – as a base for padding & margin
$colWidth: 5.25rem; // 16px * 5.25 = 84px
/*--------------------------------------------------------------
# HEADER VARS
--------------------------------------------------------------*/
$headerHeight: 160px;
$headerHeight_xs: 148px;
$topBarMinHeight: 32px;
// Desktop View Logo Versions
$lightLogo: url(assets/img/kcnb-logo@2x.png); // Logo Ref for Light Backgrounds & White BG
$darkLogo: url(assets/img/kcnb-logo-wht@2x.png); // Logo Ref for Dark Backgrounds & Dark BG
$logoWidth: 248px;
$logoHeight: 40px;
// Mobile View Logo Versions
/*$lightLogo_xs: url(assets/img/mayerhof-felix_logo_light_xs@2x.png); // Logo Ref for Light Backgrounds & White BG
$darkLogo_xs: url(assets/img/mayerhof-felix_logo_dark_xs@2x.png); // Logo Ref for Dark Backgrounds & Dark BG
$logoWidth_xs: 239px;
$logoHeight_xs: 63px;*/
/*--------------------------------------------------------------
# GLOBAL BEHAVIOUR
--------------------------------------------------------------*/
$smoothScroll: false; // turns on native smooth scrolling for anchor links
/*--------------------------------------------------------------
# BASIC GFX ASSETS
--------------------------------------------------------------*/
//$enterIcon: url(assets/img/enter_icon@2x.png);
$scrollIconWidth: 50px;
$scrollIconHeight: 50px;
/*--------------------------------------------------------------
# WEBFONTS
--------------------------------------------------------------*/
// defined in _typography.scss
/*--------------------------------------------------------------
# BREAKPOINTS
--------------------------------------------------------------*/
// from device width https://semantic-ui.com/elements/container.html
/*$bp--sm: 768px;
$bp--md: 992px;
$bp--lg: 1200px;*/
$mediaDirection: min; // min / max ('min' would be mobile-first)
// Breakpoint Sass Map
$breakpoints: (
    'default':'',
    'xxs': 369px,
    'xs': 479px,
    's': 569px,
    'ssm': 649px,
    'sm': 779px,
    'smm': 859px,
    'm': 959px,
    'ml': 1183px,
    'l': 1279px,
    'xl': 1679px,
);
// auch denkbar: xs 480 / s 667 / m 1024 / l 1280 / xl 1680
/*--------------------------------------------------------------
# COLORS
--------------------------------------------------------------*/
$blk: #333030;
$softblack: #555050;
$wht: #ffffff;
$lightgrey: #f8f7f7;
$darkblue: #213E52; // evtl. auch #363D4C
$darkblue2: #1d384b;
$darkestblue: #1b2a34;
$primary: #497A9B;
$secondary: #e0bd89;
$green: #77a464;
$crazygreen: #00ee00;
$red: #B35454;
$yellow: #E2CE78;