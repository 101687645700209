/*--------------------------------------------------------------
# Resets
--------------------------------------------------------------*/
// Reset margin at bottom of page (below footer)
body.page,
article.page {
    margin-bottom: 0;
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
.site-page {
    padding-top: $gutter;
}
.site-main {
    // margin-top to move the content by the header-height
    //margin-top: $headerHeight;
    padding-left: $gutterHalf;
    padding-right: $gutterHalf;
}
.flexlay {
    &.reverse-on-mobile {
        display: flex;
        flex-direction: column-reverse;
    }
    .col__auto {
        margin-top: $gutter;
    }
    .inset {
        padding-left: $gutter * 1.5;
        padding-right: $gutter * 1.5;
    }
}
.section-content {
    //max-width: $mediumPWidth; // das noch so anpassen, das es für Tablet/Smartphone optimal ist
    margin-left: auto;
    margin-right: auto;
    padding-top: $gutter * 2;
    padding-bottom: $gutter * 3;
    
    &.inset {
        padding-left: $gutter * 1.5;
        padding-right: $gutter * 1.5;
    }
    &.align-right {
        margin-right: 0;
    }
    &.align-left {
        margin-left: 0;
    }
    &.no-top {
        padding-top: 0;
    }
    &.bottom-padding {
        &-7 { padding-bottom: 5rem; }
        &-8 { padding-bottom: 5rem; }
        &-10 { padding-bottom: 6rem; }
    }
}
.section-padded {
    padding-top: $gutter * 2;
    padding-bottom: $gutter * 3;
}
.section-lowtop {
    padding-top: 0;
    padding-bottom: $gutter * 3;
}
.full-height {
    min-height: 100vh;
}
// Das muss ich nochmals etwas testen, vermutlich mit einem weiteren Container um den Hero
//@include parallax-init(1, ".hero-container", false);

.hero {
    //@include parallax(-.4);
    margin-top: -$headerHeight;
    background-size: cover;
    background-position: 55% 0%;
    .section-content {
        padding-top: 20vh;
    }
}
// Attention: this has to be customized for the image ratio!
// Special trick to auto-adjust the div height for background images
.auto-height {
    height: 0;
    padding-top: 52.5%;
}
// freeze the body when mobile menu is open
// might not work properly on iOS
.freeze {
    //position: fixed;
    height: 100vh;
    overflow-y: hidden;
}
.spacer {
    padding-top: $gutter;
    padding-bottom: $gutter;
}
.spacer-2 {
    padding-top: $gutter * 2;
    padding-bottom: $gutter * 2;
}
.extra-top {
    margin-top: 3rem;

    &-35 {
        margin-top: 3.5rem;
    }
    &-4 {
        margin-top: 4rem;
    }
    &-45 {
        margin-top: 4.5rem;
    }
    &-5 {
        margin-top: 5rem;
    }
    &-6 {
        margin-top: 6rem;
    }
}
.extra-left {
    padding-left: 0;
}
.no-bottom {
    padding-bottom: 0;
    margin-bottom: 0;
}
.hide-overflow {
    overflow: hidden;
}
.content-block {
    margin-top: $gutter * 3;
    margin-bottom: $gutter * 3;
    line-height: $baseLineHeight;
}
.hide-s {
    //display: none;
    height: 1px;
    > * {
        display: none;
    }
}
/* on event pages, when no image is used */
.no-img-extra-pad {
    padding-top: 2rem;
}

@include breakpoint('s') {
    .section-content {
        max-width: $mediumPWidth; // das noch so anpassen, das es für Tablet/Smartphone optimal ist
        &.no-limit {
            max-width: none;
        }
    }
}
@include breakpoint('ssm') {
    .section-content {
        &.inset {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .flexlay {
        .inset {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
@include breakpoint('sm') {
   
    .flexlay {
        &.flex-on-tablet {
            display: flex;
        }
    }
}
/* Change the layout to 2 columns with flexbox */
@include breakpoint('m') {

    .section-content, .section-padded {
        max-width: $contentWidth;
        padding-top: $gutter * 4;
        padding-bottom: $gutter * 5.25;
        &.inset {
            padding-left: $gutter * 2;
            padding-right: $gutter * 2;
        }
        /* Reset no-limit from m upwards to "normal" max-width setting */
        &.no-limit {
            max-width: $contentWidth;
        }
    }

    .hero {
        background-position: 0% 0%;
        .section-content {
            padding-top: 25vh;
        }
    }

    .flexlay {
        display: flex;
        flex-wrap: wrap;
        &.reverse-on-mobile {
            flex-direction: row;
        }

        &.centered {
            justify-content: center;
        }

        .col__one-third {
            width: 33.3333%;
            padding-right: $gutter * 2;
        }
        .col__two-thirds {
            width: 66.6666%;
        }
        .col__one-half {
            width: 50%;

            &.first {
                padding-right: $gutter * 2;
            }
        }
        .col__auto {
            flex: 1 1 auto;
            margin-top: 0;
            
            &:last-of-type {
                //padding-left: $gutter;
            }
        }
    }
    .spacer {
        padding-top: $gutter * 2;
        padding-bottom: $gutter * 2;
    }
    .spacer-2 {
        padding-top: $gutter * 4;
        padding-bottom: $gutter * 4;
    }
    .hide-m, .hide-l {
        display: none;
    }
    .hide-s {
        //display: block;
        height: auto;
        > * {
            display: block;
        }
    }
    /*.no-img-extra-pad {
        padding-top: 6.25rem;
    }*/
}
@include breakpoint('l') {
    .section-content, .section-padded {
        max-width: $contentWidth;
        padding-top: $gutter * 4;
        padding-bottom: $gutter * 4;
        &.inset {
            padding-left: 0;
            padding-right: 0;
        }
        &.bottom-padding {
            /*&-1 { padding-bottom: 1rem; }
            &-2 { padding-bottom: 2rem; }
            &-3 { padding-bottom: 3rem; }
            &-4 { padding-bottom: 4rem; }
            &-5 { padding-bottom: 5rem; }
            &-6 { padding-bottom: 6rem; }*/
            &-7 { padding-bottom: 7rem; }
            &-8 { padding-bottom: 8rem; }
            //&-9 { padding-bottom: 9rem; }
            &-10 { padding-bottom: 10rem; }
            /*&-11 { padding-bottom: 11rem; }
            &-12 { padding-bottom: 12rem; }*/
        }
    }

    .hero {
        background-position: 0% 0%;
        .section-content {
            padding-top: 25vh;
        }
    }

    .extra-left {
        padding-left: 4rem !important;
    }
    .hide-l {
        display: none;
    }
    .hide-s {
        //display: block;
        height: auto;
        > * {
            display: block;
        }
    }
    /*.no-img-extra-pad {
        padding-top: 5.65rem;
    }*/
}
/*--------------------------------------------------------------
# Themes / Colors
--------------------------------------------------------------*/
.light-theme {
    background-color: $wht;
    color: $blk;
}

.dark-theme {
    background-color: $darkblue;
    color: $wht;
    
    h1,h2,h3,h4,h5,h6 {
        color: $wht;
    }
    
    a {
        color: $wht;
    }
    
    a:hover, a:active { // I have removed a:focus because of the accordion title
        color: $primary;
    }
}

.darkest-theme {
    background-color: $darkestblue;
    color: $wht;
    
    h1,h2,h3,h4,h5,h6 {
        color: $wht;
    }
    
    a {
        color: $primary;
    }
    
    a:hover, a:active {
        color: $wht;
    }
}

.primary-theme {
    background-color: $primary;
    color: $blk;
}

.lightgrey-theme {
    background-color: $lightgrey;
    color: $blk;
}
.primary-color {
    color: $primary;
}
/*--------------------------------------------------------------
# Hero / Img Slider
--------------------------------------------------------------*/
.img-slider {

    margin-top: $gutter * 2;
    margin-bottom: $gutter * 2;

    img.slider-placeholder {
        max-width: none;
        max-height: 60vh;
    }
}
@include breakpoint('ml') {

    .img-slider {
        
        margin-top: 0;
        margin-bottom: 0;

        img.slider-placeholder {
            max-width: none;
            max-height: none;
        }

    }
}

/*--------------------------------------------------------------
# Special Layout Elements
--------------------------------------------------------------*/
// the dark blue "marker" label on past event pages
.past-event-label {
    width: 184px;
    height: 72px;
    padding: $gutter;
    margin-bottom: $gutter * 2;

    span {
        line-height: 1;
    }

    @include breakpoint('s') {
        height: 80px;
    }

    @include breakpoint('m') {
        width: 160px;
        height: 160px;
    }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.site-footer {
    padding: $gutterHalf;
    //padding-top: 0;
    section {
        margin-top: $gutterHalf;
        margin-bottom: $gutterHalf;
    }
}
@include breakpoint('xs') {
    .site-footer {
        section {
            .section-content {
                & > div.flexlay {
                    display: flex;

                    & > .col__one-half {
                        flex: 1 1 50%;

                        &:last-of-type {
                            margin-left: $gutter;
                        }
                    }
                }
            }
        }
    }
}
@include breakpoint('m') {
    .site-footer {
        section {
            .section-content {
                & > div.flexlay {
                    display: flex;

                    & > .col__one-half {
                        // reset to default flex setting
                        flex: 0 1 auto;

                        &:last-of-type {
                            //remove margin
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}
/*--------------------------------------------------------------
# Socket
--------------------------------------------------------------*/
.socket {
    min-height: 3rem * $scale;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: (.5rem * $scale) (1rem * $scale);
    font-size: 80%;
    text-align: center;
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.site-header {
    position: relative;
    //top: 0;
    width: 100%;
    height: $headerHeight;
    z-index: 10;
}
.site-header-moveable {
    position: absolute;
    top: 0;
    width: 100%;
    height: $headerHeight;
}
.site-header-bg {
    position:relative;
    margin-left: $gutter;
    margin-right: $gutter;
    max-width: 100%;
    height: 100%;
    background: $wht;
    &.dark-theme {
        background: $darkblue;
    }
    &.lightgrey-theme {
        background: $lightgrey;
    }
    &.bg-transp {
        background: transparent;
    }
}
.site-header-main-con {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: $baseWidth;
    height: 100%;
    padding-left: $gutter * 2;
    padding-right: $gutter * 2;
  
    display: flex;
    flex-direction: column; /* default */
    justify-content: flex-start;
    align-items: center;
}
.site-header.justlogo {
    .site-header-main-con {
        justify-content: center;
    }
}
/* Logo Container */
.site-branding {
    //margin-top: 3rem; // war für die Einweihungsseite korrekt (Logo zentriert, keine Nav)
    margin-top: 3rem;
    z-index:10;
}
.header-hidden {
    position: fixed;
    background: $wht;
    top: $headerHeight * -1;
    animation: headerSlideIn 0.4s ease-out;

    .site-header-bg.lightgrey-theme {
        background: $wht;
    }
}
.header-visible {
    position: fixed;
    background: $wht;
    animation: headerSlideOut 0.6s ease-out;
    // on-site header margin-top less
    .site-branding {
        //margin-top: 2rem;
    }
    .site-header-bg.lightgrey-theme {
        background: $wht;
    }
}
@keyframes headerSlideOut {
    0% {
      transform: translateY($headerHeight * -1);
    }
    100% {
      transform: translateY(0);
    }
}
@keyframes headerSlideIn {
    0% {
      transform: translateY($headerHeight);
    }
    100% {
      transform: translateY(0);
    }
}
/* switching to burger menu */
@include breakpoint('s') {

    .site-header-main-con {
        flex-direction: row; /* default */
        //justify-content: flex-start; /* default */
        //align-items: center;
    }
    /* Logo Container */
    .site-branding {
        //margin-top: 3rem; // war für die Einweihungsseite korrekt (Logo zentriert, keine Nav)
        margin-top: 0.35rem; // war 1.35rem
    }
}
/* switching to burger menu */
@include breakpoint('ml') {

    .site-header-main-con {
        padding-left: 32px;
        padding-right: 32px;
    }
}
/*
@include breakpoint('s') {
    .header-hidden {
        top: $headerHeight_xs * -1;
        animation: headerSlideInXS 0.4s ease-out;
    }
    .header-visible {
        animation: headerSlideOutXS 0.6s ease-out;
    }
    @keyframes headerSlideOutXS {
        0% {
          transform: translateY($headerHeight_xs * -1);
        }
        100% {
          transform: translateY(0);
        }
    }
    @keyframes headerSlideInXS {
        0% {
          transform: translateY($headerHeight_xs);
        }
        100% {
          transform: translateY(0);
        }
    }
}*/
/*--------------------------------------------------------------
# Main Logo
--------------------------------------------------------------*/
.logo {
    background: transparent;
    background-image: $lightLogo;
    background-size: $logoWidth $logoHeight;
    background-repeat: no-repeat;
    width: $logoWidth;
    height: $logoHeight;
    cursor: pointer;
    //margin-left: auto;
    //margin-right: auto;
    &.dark-theme,
    &.primary-theme {
        background-image: $darkLogo;
    }
}
