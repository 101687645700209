/* Change the layout to 2 columns with flexbox */
@include breakpoint('m') {
    .section-content {
        &.expandable {
            &.expand {
                max-width: 100%;
            }
        }
    }
    .flexlay {
        
        &.collapsible {

            .col__one-half {
                //width: 50%;
    
                &.first {
                    //padding-right: $gutter * 2;
                    //overflow: hidden;
                    opacity: 1;
                    transition: opacity .4s ease-in;

                    &.fade-out {
                        opacity: 0;
                    }
                    &.collapse {
                        width: 0;
                        padding-right: 0;
                        // height: 0 or display: none both solved the problem, that the container jumped in height
                        height: 0;
                    }
                }
                &:last-child {
                    &.expand {
                        width: 100%;
                    }
                }
            }
        }
    }
}

